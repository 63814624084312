import axios from 'axios'
import {ENDPOINTS} from '@/config'

export default {
  state: {},
  mutations: {},
  actions: {

    getAccount (context, id) {
      return axios.get(ENDPOINTS.accounts + id).then(resp => {
        return resp.data
      }).catch(error => {
        return error
      })
    }

  },
}
