import axios from 'axios'
import { ENDPOINTS } from '@/config'

export default {
  state: {
    companyImage: false
  },
  mutations: {
    setCompanyImage (state, image) {
      state.companyImage = image
    }
  },
  actions: {

    getConfigs ({commit}) {
      return axios.get(ENDPOINTS.configuration).then(resp => {
        let companyImage = resp.data.companyImage
        window.localStorage.setItem('companyImage', companyImage)
        commit('setCompanyImage', companyImage)
        return resp.data
      })
    },

    updateConfigs (context, params) {
      return axios.put(`${ENDPOINTS.configuration}${params.id}`, params)
    },

    setCompanyImg ({commit}, img) {
      commit('setCompanyImage', img)
    }

  }
}
