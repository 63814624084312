import axios from 'axios'
import {apiUrl, ENDPOINTS} from '@/config'

axios.defaults.baseURL = apiUrl
let authUser = JSON.parse(window.localStorage.getItem('authUser'))
if (authUser) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authUser.access_token}`
}

export default {
  state: {},
  mutations: {},
  actions: {

    getStatus () {
      return axios.get(ENDPOINTS.statusAll)
        .then(resp => {
          return resp.data
        })
    }

  }
}
