import axios from 'axios'
import {ENDPOINTS} from '@/config'

export default {
  state: {},
  mutations: {},
  actions: {

    getBank () {
      return axios.get(ENDPOINTS.banks).then(resp => {
        return resp.data
      }).catch(error => {
        return error
      })
    }

  },
}
