import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'app',
    component: () => import('../views/Login')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login')
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Login')
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: () => import('../views/ForgotPassword')
  },
  {
    path: '',
    name: 'base-app',
    component: () => import('../views/BaseApp'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '/dashboard',
        component: () => import('../views/Dashboard'),
        name: 'dashboard',
        meta: {
          title: 'Dashboard',
          requiresAuth: true,
          module: 'dashboard',
          permission: ['read']
        }
      },
      {
        path: '/indications',
        component: () => import('../views/Indications'),
        name: 'indications',
        meta: {
          title: 'Indicações',
          requiresAuth: true,
          module: 'indication',
          permission: ['read']
        }
      },
      {
        path: '/indications/:status',
        component: () => import('../views/Indications'),
        name: 'indicationsByStatus',
        meta: {
          title: 'Indicações',
          requiresAuth: true,
          module: 'indication',
          permission: ['read']
        }
      },
      {
        path: '/transfers',
        component: () => import('../views/Transfer'),
        name: 'transfers',
        meta: {
          title: 'Transferências',
          requiresAuth: true,
          module: 'transfer',
          permission: ['read']
        }
      },
      {
        path: '/indicators',
        component: () => import('../views/Indicators'),
        name: 'indicators',
        meta: {
          title: 'Indicadores',
          requiresAuth: true,
          module: 'indicators',
          permission: ['read']
        }
      },
      {
        path: '/sellers',
        component: () => import('../views/Sellers'),
        name: 'sellers',
        meta: {
          title: 'Vendedores',
          requiresAuth: true,
          module: 'sellers',
          permission: ['read']
        }
      },
      {
        path: '/configs',
        component: () => import('../views/Configs'),
        name: 'configs',
        meta: {
          title: 'Configurações',
          requiresAuth: true,
          module: 'configs',
          permission: ['read']
        }
      },
      {
        path: '/configs/:tab',
        component: () => import('../views/Configs'),
        name: 'configsByTab',
        meta: {
          title: 'Configurações',
          requiresAuth: true,
          module: 'configs',
          permission: ['read']
        }
      },

      {
        path: '/users',
        component: () => import('../views/Users'),
        name: 'users',
        meta: {
          title: 'Usuários',
          requiresAuth: true,
          module: 'users',
          permission: ['read']
        }
      }
    ]
  },
  {
    path: '/indication-shared/:id',
    component: () => import('../views/IndicationShared'),
    name: 'indicationsShared',
    meta: {
      title: 'Indicação Compartilhada',
      requiresAuth: false,
      module: 'indication',
      permission: ['read']
    }
  },
  {
    path: '/politica-e-privacidade',
    component: () => import('../views/PrivacyPolicy.vue'),
    name: 'privacyPolicy',
    meta: {
      title: 'Política e Privacidade'
    }
  },
  {
    path: '*',
    component: () => import('../views/errors/404')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
