import Vue from 'vue'
import moment from 'moment'

Vue.filter('formatDate', function (date) {
  return date ? moment(date).format('DD/MM/YYYY') : ''
})

Vue.filter('formatDatetime', function (date) {
  return date ? moment(date).format('DD/MM/YYYY HH:mm:ss') : ''
})

Vue.filter('currence', function (value) {
  if (value == null) {
    return value
  } else {
    if (typeof value === 'number') {
      return value.toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
    } else {
      return parseFloat(value).toLocaleString('pt-BR', {style: 'currency', currency: 'BRL'})
    }
  }
})

Vue.filter('truncateStr', function (string) {
  if (string.length >= 20) {
    return string.substring(0, 20) + '...'
  } else {
    return string
  }
})

Vue.mixin({
  methods: {
    indicationText (dataVal) {
      return dataVal > 1 ? 'indicações' : 'indicação'
    },
    calcPercentageBalance (dataVal) {
      return dataVal <= 10 ? 'red' : dataVal >= 11 && dataVal <= 25 ? 'orange' : 'green'
    },
    shareContent (title, text, url) {
      if (navigator.share) {
        navigator.share({
          title: title,
          text: text,
          url: url,
        })
      }
    }
  }
})
