import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import alertStore from '@/store/alertStore'
import accuntStore from '@/store/accountStore'
import bankStore from '@/store/bankStore'
import configurationStore from '@/store/configurationStore'
import indicationStore from '@/store/indicationStore'
import userStore from '@/store/userStore'
import statusStore from '@/store/statusStore'

export default new Vuex.Store({
  modules: {
    alertStore,
    accuntStore,
    bankStore,
    configurationStore,
    indicationStore,
    statusStore,
    userStore
  }
})
