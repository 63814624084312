import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import userStore from './store/userStore'
import {MediaQueries, CommonBands} from 'vue-media-queries'
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap-icons/font/bootstrap-icons.min.css'
import '@/plugin/filters'
import axios from 'axios'

const mediaQueries = new MediaQueries({
  bands: CommonBands.Bulma
})
Vue.use(mediaQueries)

const options = {
  // duração da notificação (em ms)
  timeout: 5000,
  // posição da notificação top-center top-right top-left bottom-center bottom-left bottom-right 
  position: 'bottom-center', 
  // outras opções disponíveis na documentação da biblioteca
};

Vue.use(Toast, options);

axios.interceptors.response.use(
  response => {
    if (response.status === 200 || response.status === 201) {
      return Promise.resolve(response);
    }
  },
  error => {
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          if (error.response.data.error === 'invalid_token') {
            window.localStorage.clear()
            window.location = '/'
            break
          }
      }
      return Promise.reject(error.response);
    } else {
      let authUser = JSON.parse(window.localStorage.getItem('authUser'))
      if (error.code === "ERR_NETWORK" && authUser) {
        window.localStorage.clear()
        window.location = '/'
      }
    }
  }
);

import VueResource from 'vue-resource'
import './registerServiceWorker'

Vue.use(VueResource)

Vue.config.productionTip = false

router.beforeEach((to, from, next) => {
  store.dispatch('hideAlert')
  if (to.meta.requiresAuth) {
    let authUser = JSON.parse(window.localStorage.getItem('authUser'))
    if (!authUser || (authUser && !authUser.user)) {
      window.localStorage.clear()
      next('/login')
      return false
    }
  }
  next()
})

new Vue({
  router,
  store,
  userStore,
  mediaQueries: mediaQueries,
  render: h => h(App)
}).$mount('#app')
