const host = window.location.host
const isProduction = process.env.NODE_ENV === 'production'
let protocol = isProduction ? 'https://' : 'http://'
export const appUrl = protocol + host + '/'
export const endpointUrl = 'https://prod.mestreindicador.com.br/'
export const apiUrl = endpointUrl + 'api/'

export function getBearer () {
  let authUser = JSON.parse(window.localStorage.getItem('authUser'))
  if (authUser) {
    return `Bearer ${authUser.access_token}`
  }
}

export const ENDPOINTS = {

  accounts: `${apiUrl}accounts/`,
  banks: `${apiUrl}bank/`,
  configuration: `${apiUrl}configuration/`,
  indications: `${apiUrl}indications/`,
  oauth: `${endpointUrl}oauth/token`,
  status: `${apiUrl}status/`,
  users: `${apiUrl}users`,

  // variations

  // indications
  indicationsAll: `${apiUrl}indications/all?`,
  dashboard: `${apiUrl}indications/dashboard/indicator/`,
  dashboardSeller: `${apiUrl}indications/dashboard/seller/`,
  dashboardCompany: `${apiUrl}indications/dashboard/company/`,
  transfer: `${apiUrl}indications/transferred/`,
  availableBalance: `${apiUrl}indications/availableBalance/`,

  // status
  statusAll: `${apiUrl}status/all?`,

  // users
  usersAll: `${apiUrl}users/all?`,
  forgotPassword: `${apiUrl}users/email`,
  recoveryPassword: `${apiUrl}users/password/`,

}
