import axios from 'axios'
import {apiUrl, ENDPOINTS, getBearer} from '@/config'

axios.defaults.baseURL = apiUrl
let authUser = JSON.parse(window.localStorage.getItem('authUser'))
if (authUser) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${authUser.access_token}`
}

export default {
  state: {},
  mutations: {},
  actions: {

    getIndications (context, params) {
      return axios.get(ENDPOINTS.indicationsAll + params).then(resp => {
        return resp.data
      })
    },

    newIndication (context, indication) {
      return axios.post(`${ENDPOINTS.indications}`, indication).then(resp => {
        return resp.data
      }).catch(error => {
        return error
      })
    },

    updateIndication (context, indication) {
      return axios.put(`${ENDPOINTS.indications}${indication.id}`, indication)
    },

    dashboard (context, params) {
      return axios.get(`${ENDPOINTS.dashboard}${params.id}`, {
        params,
        headers: {
          'Authorization': getBearer()
        }
      }).then(resp => {
        return resp.data
      }).catch(error => {
        return error
      })
    },

    dashboardSeller (context, params) {
      return axios.get(`${ENDPOINTS.dashboardSeller}${params.id}`, {
        params,
        headers: {
          'Authorization': getBearer()
        }
      }).then(resp => {
        return resp.data
      }).catch(error => {
        return error
      })
    },

    dashboardCompany (context, params) {
      return axios.get(`${ENDPOINTS.dashboardCompany}`, {
        params,
        headers: {
          'Authorization': getBearer()
        }
      }).then(resp => {
        return resp.data
      }).catch(error => {
        return error
      })
    },

    transfer (context, id) {
      return axios.put(`${ENDPOINTS.transfer}${id}`).then(resp => {
        return resp.data
      }).catch(error => {
        return error
      })
    },

    getAvailableBalance (context, id) {
      return axios.get(`${ENDPOINTS.availableBalance}${id}`).then(resp => {
        return resp.data
      })
    }

  }
}
