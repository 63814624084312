import axios from 'axios'
import {apiUrl, ENDPOINTS} from '@/config'

axios.defaults.baseURL = apiUrl

function updateHeader (accessToken) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`
}

function authUser () {
  let authUser = JSON.parse(window.localStorage.getItem('authUser'))
  return authUser ? authUser : {user: null}
}

export default {
  state: {
    userImage: false,
    userData: false
  },
  mutations: {
    setUserImage (state, image) {
      state.userImage = image
    },
    setUserData (state, image) {
      state.userData = image
    }
  },
  actions: {

    login (context, params) {
      return axios.post(
        ENDPOINTS.oauth,
        params,
        {
          auth: {
            username: "my-app",
            password: "ZWZjb2RlN3NlY3JldF8="
          },
          headers: {'Content-Type': 'application/x-www-form-urlencoded'}
        }
      ).then(resp => {
        window.localStorage.setItem('authUser', JSON.stringify({
          access_token: resp.data.access_token
        }))
        updateHeader(resp.data.access_token)
        return {
          status: 200
        }
      }).catch(() => {
        return {
          data: {
            errors: [{
              defaultMessage: 'E-mail e ou senha inválidos'
            }]
          },
          status: 400
        }
      })
    },

    registerUser (context, params) {
      return axios.post(ENDPOINTS.users, params, {}).then(resp => {
        window.localStorage.setItem('authUser', JSON.stringify({
          user: resp.data
        }))
        return resp
      }).catch(resp => {
        return resp
      })
    },

    forgotPassword (context, params) {
      return axios.put(ENDPOINTS.forgotPassword, params, {}).then(resp => {
        return resp
      }).catch(resp => {
        return resp
      })
    },

    recoveryPassword (context, params) {
      return axios.put(`${ENDPOINTS.recoveryPassword}${params.id}`, params).then(resp => {
        return resp.data
      }).catch(error => {
        return error
      })
    },

    getAllUsers (context, params) {
      return axios.get(ENDPOINTS.usersAll, {params}).then(resp => {
        return resp.data
      }).catch(error => {
        return error
      })
    },

    getUser ({commit}, email) {
      return axios.get(`${ENDPOINTS.users}/${email}`).then(resp => {
        let auth = authUser()
        auth.user = resp.data
        window.localStorage.setItem('authUser', JSON.stringify(auth))
        commit('setUserImage', auth.user.image)
        return resp.data
      })
    },

    newUser (context, params) {
      return axios.post(ENDPOINTS.users, params).then(resp => {
        return resp.data
      }).catch(error => {
        return error
      })
    },

    updateUser (context, params) {
      return axios.put(`${ENDPOINTS.users}/${params.id}`, params).then(resp => {
        return resp.data
      }).catch(error => {
        return error.data
      })
    },

    deleteUser (context, id) {
      return axios.delete(`${ENDPOINTS.users}/${id}`)
    },

    setUserImage ({commit}, image) {
      commit('setUserImage', image)
    },

    setUserData ({commit}, image) {
      commit('setUserData', image)
    },

    createAccount (context, params) {
      return axios.post(ENDPOINTS.accounts, params).then(resp => {
        return resp
      }).catch(resp => {
        return resp
      })
    },

    updateAccount (context, params) {
      let nParams = {...params}
      delete nParams.id
      return axios.put(`${ENDPOINTS.accounts}${params.id}`, nParams).then(resp => {
        return resp.data
      }).catch(error => {
        return error
      })
    },

    deleteAccount (context, id) {
      return axios.delete(`${ENDPOINTS.users}/delete-account/${id}`)
    },

  }
}
