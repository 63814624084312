export default {
  state: {
    alert: {
      duration: 10000,
      message: '',
      position: 'bottom-right',
      show: false,
      title: '',
      type: 'info'
    }
  },
  mutations: {
    setAlertDanger (state, message) {
      state.alert.type = 'danger'
      state.alert.title = 'Atenção!'
      state.alert.message = message
      state.alert.show = true
    },
    setAlertSuccess (state, message) {
      state.alert.type = 'success'
      state.alert.title = 'Sucesso!'
      state.alert.message = message
      state.alert.show = true
    },
    hideAlert (state) {
      state.alert.show = false
    }
  },
  actions: {
    setAlertDanger ({commit}, data) {
      commit('setAlertDanger', data)
    },
    setAlertSuccess ({commit}, data) {
      commit('setAlertSuccess', data)
    },
    hideAlert ({commit}) {
      commit('hideAlert')
    }
  }
}
